
import { defineComponent, computed, ref, Ref } from 'vue'
import { User } from '@element-plus/icons-vue'
import { routeList } from '@/router'
import { useRouter, useRoute } from 'vue-router'
import { isString } from 'lodash'
import { useStore } from '@/store'
import { identityGroup } from '@/models'
import useDrawer from '@/hooks/useDrawer'
import { isObject } from 'lodash'

export default defineComponent({
  components: { User },
  name: 'TheHeader',
  props: {},
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const menuIndex: Ref<string | null> = ref(null)
    const menuList = [
      {
        label: '客製雨傘',
        name: routeList['custom-umbrella']
      },
      {
        label: '我的設計',
        name: routeList['my-design']
      },
      {
        label: '個人圖庫',
        name: routeList['photo-storage']
      }
    ]
    const userProfile = computed(() => store.state.useProfile)
    const displayMenuName = computed(() => {
      if (menuIndex.value === routeList.permission) {
        return '權限管理'
      } else {
        return menuList.find((item) => {
          return item.name === menuIndex.value
        })?.label
      }
    })
    const { isVisible, openDrawer, closeDrawer } = useDrawer()
    const handleHamburgerClick = () => {
      openDrawer()
    }
    const handleTabChange = () => {
      if (isString(menuIndex.value)) {
        router.push({ name: menuIndex.value })
      }
    }
    const handleMenuItemClick = (value: string) => {
      if (menuIndex.value !== value) {
        menuIndex.value = value
        handleTabChange()
        closeDrawer()
      }
    }
    const userNickName = computed(() => store.state.useProfile?.nickname)
    const handleLogout = () => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessTokenExp')
      localStorage.removeItem('refreshTokenExp')
      router.push({ name: routeList.login })
    }

    const useName = computed(() => {
      if (isObject(userProfile.value)) {
        if (userProfile.value.groups.includes(identityGroup.admin)) {
          return '管理員'
        }
        if (userProfile.value.groups.includes(identityGroup.sales)) {
          return '業務'
        }
      }
      return ''
    })
    const init = () => {
      if (isString(route.name)) {
        if (route.name.includes('_')) {
          menuIndex.value = route.name.split('_')[0]
        } else {
          menuIndex.value = route.name
        }
      }
    }

    init()

    return {
      menuIndex,
      menuList,
      userNickName,
      routeList,
      userProfile,
      identityGroup,
      isVisible,
      displayMenuName,
      useName,
      handleMenuItemClick,
      handleTabChange,
      handleHamburgerClick,
      handleLogout
    }
  }
})
